<template>
  <div>
    <EntitiesGallery method="parties" entity="party"/>
  </div>
</template>
<script>
import EntitiesGallery from "@/components/entities/EntitiesGallery";

export default {
  components: {
    EntitiesGallery
  }
}
</script>